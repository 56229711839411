
// in app.js
import * as React from "react"
import { Admin, Resource, DeleteButton } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider';
import { i18nProvider as polyglotI18nProvider } from './polyglotProvider'
import Dashboard from './Dashboard'
import Theme from './Theme'
import LoginPage from './LoginPage'
import Menu from './Menu'

import { CompanyList, CompanyShow, CompanyCreate, CompanyEdit, CompanyIcon } from './components/company'
import { FicheList, FicheShow, FicheIcon } from './components/fiches'
import { EstablishmentList, EstablishmentShow, EstablishmentIcon } from './components/establishments'
import { CodepostalList, CodepostalShow, CodepostalIcon } from './components/codepostals'
import { InpiList, InpiShow, InpiCreate, InpiEdit, InpiIcon } from './components/inpi'
import { PreuserList, PreuserShow, PreuserCreate, PreuserIcon } from './components/preuser'
import { UserList, UserShow, UserCreate, UserEdit, UserIcon } from './components/user'
import { TenantList, TenantShow, TenantCreate, TenantEdit, TenantIcon } from './components/tenant'
import { RequestList, RequestShow, RequestIcon } from './components/request'
import { BlogpostList, BlogpostShow, BlogpostCreate, BlogpostEdit, BlogpostIcon } from './components/blogpost'
import { JobList, JobShow, JobCreate, JobEdit, JobIcon } from './components/job'
import { ScrappedResourceList, ScrappedResourceShow, ScrappedResourceEdit, ScrappedResourceIcon } from './components/scrappedresources'
import { KnowledgeList, KnowledgeShow, KnowledgeIcon } from './components/knowledges'
import { LinkedinLinkList, LinkedinLinkShow, LinkedinLinkIcon } from './components/linkedin_links'
import { BusinessnewList, BusinessnewShow, BusinessnewIcon } from './components/businessnews'

import { BrowserRouter as Router, Route } from "react-router-dom";

import CountingPage from './pages/CountingPage'
import Counting2Page from './pages/Counting2Page'
import FichesActivitePage from './pages/FichesActivitePage'
import EstablishmentsActivitePage from './pages/EstablishmentsActivitePage'
import EstablishmentsDepartementPage from './pages/EstablishmentsDepartementPage'
import Graph from './pages/Graph'
import GraphTracking from './pages/GraphTracking'
import GraphValueserp from './pages/GraphValueserp'
import StripeDashboard from './pages/StripeDashboard'
import MailPage from "./pages/MailPage";

import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import createAdminStore from './createAdminStore';
import VNCPage from "./pages/VNCPage";


const history = createHashHistory();


class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dashboard: null
    }
  }

  render() {
    return (
      <Provider
        store={createAdminStore({
          authProvider,
          dataProvider,
          history,
        })}
      >
        <Admin
          theme={Theme}
          menu={Menu((dashboard) => this.setState({ dashboard }))}
          dataProvider={dataProvider}
          authProvider={authProvider}
          dashboard={Dashboard(this.state.dashboard)}
          i18nProvider={polyglotI18nProvider}
          loginPage={LoginPage}
          history={history}
          customRoutes={[
            <Route exact path="/counting" component={CountingPage} />,
            <Route exact path="/counting2" component={Counting2Page} />,
            <Route exact path="/graph" component={Graph} />,
            <Route exact path="/tracking" component={GraphTracking} />,
            <Route exact path="/valueserp" component={GraphValueserp} />,
            <Route exact path="/stripe" component={StripeDashboard} />,
            <Route exact path="/fichesactivites" component={FichesActivitePage} />,
            <Route exact path="/establishmentsactivites" component={EstablishmentsActivitePage} />,
            <Route exact path="/establishmentsdepartements" component={EstablishmentsDepartementPage} />,
            <Route exact path="/mail" component={MailPage} />,
            <Route exact path="/vnc" component={VNCPage} />,
          ]}
        >
          <Resource name="Company" options={{ group: "company" }} list={CompanyList} show={CompanyShow} create={CompanyCreate} edit={CompanyEdit} icon={CompanyIcon} remove={DeleteButton} />
          <Resource name="Fiche" options={{ group: "company" }} list={FicheList} show={FicheShow} icon={FicheIcon} remove={DeleteButton} />
          <Resource name="Establishment" options={{ group: "company" }} list={EstablishmentList} show={EstablishmentShow} icon={EstablishmentIcon} remove={DeleteButton} />
          <Resource name="Codepostal" options={{ group: "company" }} list={CodepostalList} show={CodepostalShow} icon={CodepostalIcon} remove={DeleteButton} />
          <Resource name="Inpi" options={{ group: "company" }} list={InpiList} show={InpiShow} create={InpiCreate} edit={InpiEdit} icon={InpiIcon} remove={DeleteButton} />
          <Resource name="Preuser" options={{ group: "users" }} list={PreuserList} show={PreuserShow} icon={PreuserIcon} remove={DeleteButton} />
          <Resource name="User" options={{ group: "users" }} list={UserList} show={UserShow} edit={UserEdit} icon={UserIcon} remove={DeleteButton} />
          <Resource name="Request" options={{ group: "users" }} list={RequestList} show={RequestShow} icon={RequestIcon} remove={DeleteButton} />
          <Resource name="Tenant" options={{ group: "users" }} list={TenantList} show={TenantShow} create={TenantCreate} edit={TenantEdit} icon={TenantIcon} remove={DeleteButton} />
          <Resource name="Blogpost" options={{ group: "blogs" }} list={BlogpostList} show={BlogpostShow} create={BlogpostCreate} edit={BlogpostEdit} icon={BlogpostIcon} remove={DeleteButton} />
          <Resource name="Job" options={{ group: "jobs" }} list={JobList} show={JobShow} create={JobCreate} icon={JobIcon} remove={DeleteButton} />
          <Resource name="ScrappedResource" options={{ group: "scrap" }} list={ScrappedResourceList} show={ScrappedResourceShow} edit={ScrappedResourceEdit} icon={ScrappedResourceIcon} remove={DeleteButton} />
          <Resource name="Knowledge" options={{ group: "scrap" }} list={KnowledgeList} show={KnowledgeShow} icon={KnowledgeIcon} remove={DeleteButton} />
          <Resource name="LinkedinLink" options={{ group: "scrap" }} list={LinkedinLinkList} show={LinkedinLinkShow} icon={LinkedinLinkIcon} remove={DeleteButton} />
          <Resource name="Businessnew" options={{ group: "scrap" }} list={BusinessnewList} show={BusinessnewShow} icon={BusinessnewIcon} remove={DeleteButton} />
        </Admin>
      </Provider>
    )
  }
}

export default App
