
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'

const API_URL = process.env.REACT_APP_API_HOSTNAME

const mapGet = {
    'User': 'superuser/users',
    'Preuser': 'superuser/preusers',
    'Company': 'superuser/companies',
    'Fiche': 'superuser/fiches',
    'Establishment': 'superuser/establishments',
    'Codepostal': 'superuser/codepostal',
    'Inpi': 'superuser/inpi',
    'Request': 'superuser/requests',
    'Blogpost': 'superuser/blogposts',
    'Job': 'superuser/jobs',
    'ScrappedResource': 'superuser/scrappedresources',
    'Knowledge': 'superuser/knowledges',
    'LinkedinLink': 'superuser/linkedin_links',
    'Tenant': 'superuser/tenants',
    'Businessnew': 'superuser/businessnews',
}

const mapPost = {
    'User': 'superuser/user',
    'Preuser': 'superuser/preuser',
    'Company': 'superuser/company',
    'Request': 'superuser/request',
    'Inpi': 'superuser/inpi',
    'Blogpost': 'superuser/blogpost',
    'Job': 'superuser/job',
    'ScrappedResource': 'superuser/scrappedresource',
    'Knowledge': 'superuser/knowledge',
    'LinkedinLink': 'superuser/linkedin_link',
    'Tenant': 'superuser/tenant',
    'Businessnew': 'superuser/businessnew',
}


const { fetchJson } = fetchUtils

const dataProvider = {
    getList: (resource, params) => new Promise(resolve => {
        console.log('getList', { resource, params })
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort_field: field,
            sort_order: order,
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data,
            //         total: json.total
            //     })
            // }
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    getOne: (resource, params) => new Promise(resolve => {
        console.log('getOne', { resource, params })
        const url = `${API_URL}/${mapGet[resource]}?${stringify({ id: params.id })}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data[0],
            //     })
            // }
            resolve({
                data: json.data[0],
            })
        })
    }),
    getMany: (resource, params) => new Promise(async (resolve) => {
        console.log('getMany', { resource, params })
        const result = []
        for (let i = 0; i < params.ids.length; i++) {
            const ret = await dataProvider.getOne(resource, { id: params.ids[i] })
            if (ret.data)
                result.push(ret.data)
        }
        console.log(result)
        resolve({ data: result })
    }),
    getManyReference: (resource, params) => new Promise(resolve => {
        console.log('getManyReference', { resource, params })
        resolve()
    }),
    create: (resource, params) => new Promise(resolve => {
        console.log('create', { resource, params })
        if (resource === 'Professor') {
            // params.data.lastCrawlDate = new Date(params.data.lastCrawlDate).getTime()
        }
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data, id: json.id } })
        })
    }),
    update: (resource, params) => new Promise(resolve => {
        console.log('update', { resource, params })
        delete params.data.updatedAt
        delete params.data.createdAt
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data } })
        })
    }),
    updateMany: (resource, params) => new Promise(resolve => {
        console.log('updateMany', { resource, params })
        resolve()
    }),
    delete: (resource, params) => new Promise(resolve => {
        console.log('delete', { resource, params })
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        options.body = JSON.stringify({ id: params.id })
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.id } })
        })
    }),
    deleteMany: (resource, params) => new Promise(async (resolve) => {
        console.log('deleteMany', { resource, params })
        for (let i = 0; i < params.ids.length; i++) {
            await dataProvider.delete(resource, { id: params.ids[i] })
        }
        resolve({ data: [...params.ids] })
    }),
    customs: {
        getSources: (resource) => new Promise(resolve => {
            const url = `${API_URL}/superuser/company/sources/${resource}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        count: (resource, source, distinct) => new Promise(resolve => {
            let query = { source, distinct }
            if (Array.isArray(source))
                query = { sources: source.join('|'), distinct }
            const url = `${API_URL}/superuser/company/count/${resource}?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        graph: (start, end, user, type) => new Promise(resolve => {
            let query = { start, end, user, type }
            const url = `${API_URL}/superuser/graph?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        getRequestTypes: (start, end) => new Promise(resolve => {
            let query = { start, end }
            const url = `${API_URL}/superuser/request/types?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        getRequestUsers: (start, end) => new Promise(resolve => {
            let query = { start, end }
            const url = `${API_URL}/superuser/request/users?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        abortJob: (id) => new Promise(resolve => {
            const url = `${API_URL}/superuser/job/abort`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'POST'
            options.body = JSON.stringify({ id })
            fetchJson(url, options).then(json => {
                json = JSON.parse(json.body)
                resolve()
            })
        }),
        deleteCsvJob: (id) => new Promise(resolve => {
            const url = `${API_URL}/superuser/csv/${id}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'DELETE'
            options.body = JSON.stringify({})
            fetchJson(url, options).then(json => {
                json = JSON.parse(json.body)
                resolve()
            })
        }),
        trackingsGraph: (start, end) => new Promise(resolve => {
            let query = { start, end }
            const url = `${API_URL}/superuser/dashboard/trackings?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        valueserp_account: async () => {
            const url = `${API_URL}/superuser/valueserp/account`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const response = await fetchJson(url, { headers: requestHeaders })
            return JSON.parse(response.body)
        },
        stripe_dashboard: async () => {
            const url = `${API_URL}/superuser/stripe/dashboard`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const response = await fetchJson(url, { headers: requestHeaders })
            return JSON.parse(response.body)
        },
        getAlert: async (alert) => {
            const url = `${API_URL}/superuser/alert?${stringify({ alert })}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const response = await fetchJson(url, { headers: requestHeaders })
            return JSON.parse(response.body)
        },
        alert: async (alert, active) => {
            const url = `${API_URL}/superuser/alert`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'POST'
            options.body = JSON.stringify({ alert, active })
            const response = await fetchJson(url, options)
            return JSON.parse(response.body)
        },
        tenantChatGpt: (id) => new Promise(resolve => {
            const url = `${API_URL}/superuser/tenant-chat-gpt`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'POST'
            options.body = JSON.stringify({ id })
            fetchJson(url, options).then(json => {
                json = JSON.parse(json.body)
                resolve()
            })
        }),
        ficheActivites: {
            getSections: (etatAdministratifUniteLegale) => new Promise(resolve => {
                const url = `${API_URL}/superuser/fiche/activites/sections?${stringify({ etatAdministratifUniteLegale })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
            getMaj: (section, etatAdministratifUniteLegale) => new Promise(resolve => {
                const url = `${API_URL}/superuser/fiche/activites/maj?${stringify({ section, etatAdministratifUniteLegale })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
            getMin: (maj, etatAdministratifUniteLegale) => new Promise(resolve => {
                const url = `${API_URL}/superuser/fiche/activites/min?${stringify({ maj, etatAdministratifUniteLegale })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
            get: (activitePrincipaleUniteLegale, etatAdministratifUniteLegale) => new Promise(resolve => {
                const url = `${API_URL}/superuser/fiche/activites?${stringify({ activitePrincipaleUniteLegale, etatAdministratifUniteLegale })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
        },
        establishmentActivites: {
            getSections: (etatAdministratifEtablissement, region, departement) => new Promise(resolve => {
                const url = `${API_URL}/superuser/establishment/activites/sections?${stringify({ etatAdministratifEtablissement, region, departement })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
            getMaj: (section, etatAdministratifEtablissement, region, departement) => new Promise(resolve => {
                const url = `${API_URL}/superuser/establishment/activites/maj?${stringify({ section, etatAdministratifEtablissement, region, departement })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
            getMin: (maj, etatAdministratifEtablissement, region, departement) => new Promise(resolve => {
                const url = `${API_URL}/superuser/establishment/activites/min?${stringify({ maj, etatAdministratifEtablissement, region, departement })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
            get: (activitePrincipaleEtablissement, etatAdministratifEtablissement, region, departement) => new Promise(resolve => {
                const url = `${API_URL}/superuser/establishment/activites?${stringify({ activitePrincipaleEtablissement, etatAdministratifEtablissement, region, departement })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
        },
        establishmentDepartements: {
            getRegions: (etatAdministratifEtablissement, activitePrincipaleEtablissement) => new Promise(resolve => {
                const url = `${API_URL}/superuser/establishment/departements/regions?${stringify({ etatAdministratifEtablissement, activitePrincipaleEtablissement })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
            get: (etatAdministratifEtablissement, region, activitePrincipaleEtablissement) => new Promise(resolve => {
                const url = `${API_URL}/superuser/establishment/departements?${stringify({ etatAdministratifEtablissement, region, activitePrincipaleEtablissement })}`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                fetchJson(url, { headers: requestHeaders }).then(json => {
                    json = JSON.parse(json.body)
                    resolve(json)
                })
            }),
        },
        mail: {
            send: (subject, mail) => new Promise(resolve => {
                const url = `${API_URL}/superuser/mail/send`
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                const options = { headers: requestHeaders }
                options.method = 'POST'
                options.body = JSON.stringify({ subject, mail })
                fetchJson(url, options).then(json => {
                    json = JSON.parse(json.body)
                    resolve()
                })
            })
        },
    }
}

export default dataProvider
